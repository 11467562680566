import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { GoogleLoginButton } from "react-social-login-buttons";

import MainMenu from '../../menus/main/'

import './login.scss'

require('dotenv').config()

class Login extends Component {
    constructor( ) {
        super()
        this.state = {
            isLogged: false,
        }
    }

    componentDidMount() {
        if ( localStorage.getItem( 'api_token' ) ) {
            this.setState({ isLogged: true })
        }
    }

    render () {
        if ( this.state.isLogged ) {
            return ( <Redirect to="/" />)
        }
        return (
            <>
                <MainMenu currentPage="Login" />
                <div className="Login animate__animated animate__bounceInRight">
                    <div className="container">
                        <h2 className="my-5">Authentification</h2>
                        <a href={`${process.env.REACT_APP_API_URL}/auth/redirect/google`} >
                            <GoogleLoginButton />
                        </a>
                    </div>
                </div>
            </>
        )
    }
}

export default Login
