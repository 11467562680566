import React, { Component } from 'react'

import "./loading.scss"

class Loading extends Component {
    render () {
        return(
            <div className="Loading">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
}

export default Loading
