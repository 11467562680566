import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Axios from 'axios'

import MainMenu from '../../menus/main/'

import InputText from '../../components/input/text/'
import InputButton from '../../components/input/button/'

import './register.scss'

require('dotenv').config()

class Register extends Component {
    constructor( ) {
        super()
        this.state = {
            name: '',
            email: '',
            password: '',
            password_confirmed: '',
            isLogged: false,
            errors: [],
        }
    }

    componentDidMount() {
        if ( localStorage.getItem( 'api_token' ) ) {
            this.setState({ isLogged: true })
        }
    }

    handleChangeName = (event) =>  {
        this.setState({ name : event.target.value })
    }

    handleChangeEmail = (event) =>  {
        this.setState({ email : event.target.value })
    }

    handleChangePassword = (event) =>  {
        this.setState({ password : event.target.value })
    }

    handleChangePasswordConfirmed = (event) =>  {
        this.setState({ password_confirmed : event.target.value })
    }
    handleSubmit = (event) => {
        event.preventDefault()

        let bodyFormData = new FormData();
        bodyFormData.set( 'name' , this.state.name )
        bodyFormData.set( 'email' , this.state.email )
        bodyFormData.set( 'password' , this.state.password )
        bodyFormData.set( 'password_confirmed' , this.state.password_confirmed )

        Axios.post( process.env.REACT_APP_API_URL + '/api/register', bodyFormData )
        .then(res => {
            if ( res.data.success ) {
                localStorage.setItem( 'api_token', res.data.success.api_token )
                this.setState({ isLogged: true })
            }
        })
        .catch(errors =>  {
            if ( errors.response.status !== 200 ) {
                this.setState({ errors: errors.response.data.errors }, () => { console.warn( this.state.errors )})
            }
        });
    }

    render () {
        if ( this.state.isLogged ) {
            return ( <Redirect to="/" />)
        }
        return (
            <>
                <MainMenu currentPage="Register" />
                <div className="Register animate__animated animate__bounceInRight">
                    <div className="container">
                        <h2 className="my-5">Inscription</h2>
                        <form method="POST" onSubmit={this.handleSubmit} className="Forms text-start" >
                            <InputText
                                htmlId='RegisterInputName'
                                label='Nom'
                                onChange={this.handleChangeName}
                                errors={this.state.errors.name}
                            />
                            <InputText
                                htmlId='RegisterInputEmail'
                                label='Adresse email'
                                onChange={this.handleChangeEmail}
                                errors={this.state.errors.email}
                            />
                            <InputText
                                htmlId='RegisterInputPassword'
                                label='Password'
                                onChange={this.handleChangePassword}
                                errors={this.state.errors.password}
                                password={true}
                            />
                            <InputText
                                htmlId='RegisterInputPasswordConfirm'
                                label='Password confirmation'
                                onChange={this.handleChangePasswordConfirmed}
                                errors={this.state.errors.password_confirmed}
                                password={true}
                            />
                            <InputButton label="M'inscrire" />
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default Register
