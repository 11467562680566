import React from 'react'

import './inputbutton.scss'

const InputButton = (props) => {
    let { label } = props
    return (
        <>
            <button type="submit" className="btn btn-primary">{label}</button>
        </>
    )
}

export default InputButton
